import React from 'react';
import Page from '../../components/Page';
import styles from './newsletter.module.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const MailchimpURL = 'https://elmorabba3.us17.list-manage.com/subscribe/post?u=4b6b0a220e984d468e5f1500a&amp;id=7dc13ea213&amp;f_id=008c50e0f0';

const CustomForm = ({status, message, onValidated}) => {
    let email, country;
    const submit = () =>
        email &&
        country &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
            MMERGE6: country.value
        });

    return (
        <div
            style={{
                display: "inline-block",
                textAlign: "center"
            }}
        >
            <input
                className={styles.newsletterInput}
                ref={node => (email = node)}
                type="email"
                placeholder="Email *"
            />
            <input
                className={styles.newsletterInput}
                ref={node => (country = node)}
                type="text"
                placeholder="Country"
            />
            <br/>
            {status === "sending" && <div style={{color: "white"}}>Subscribing...</div>}
            {status === "error" && (
                <div
                    style={{color: "red"}}
                    dangerouslySetInnerHTML={{__html: message}}
                />
            )}
            {status === "success" && (
                <div
                    style={{color: "white"}}
                    dangerouslySetInnerHTML={{__html: message}}
                />
            )}
            <br/>
            <button className={styles.menuItem} onClick={submit}>
                Subscribe to the mailing list →
            </button>
        </div>
    );
};


function NewsletterPage() {
    return (
        <Page>
            {/*<h1 className={styles.head}>Newsletter</h1>*/}
            <div className={styles.container}>
                <div className={styles.info}>
                    <p style={{textAlign: "center",}}>
                        Subscribe to our mailing list for the latest news and updates from the band.
                        <br/>
                        <br/>
                        <small>
                            Add your country (optional) to be notified about concerts in your area!
                        </small>
                    </p>
                    <MailchimpSubscribe
                        url={MailchimpURL}
                        render={({subscribe, status, message}) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />
                </div>
            </div>
        </Page>
    );
}

export default NewsletterPage;
