import React from 'react';
import Page from '../../components/Page';
import styles from './about.module.scss';

const AboutPage = function AboutPage(props) {
	return (
		<Page>
			<h1 className={styles.head}>About us</h1>
			<div className={styles.info}>
				<p>
					Between the release of their first, self-titled album in 2012 and their second, Taraf Al Khait, in 2016, El Morabba3 caught the transformative spirit of a revolutionary generation with their trenchant indie-rock sound and lyrics. Now, after restructuring the band and moving from Amman to Berlin, founding singer/songwriter/bassist Mohammad Abdallah and the music producer and sound artist Basel Naouri are taking El Morabba3’s earlier aesthetic evolution into a new direction. Departing from the raw post-rock energy of the first album and the polished instrumental/electronic fervor of the second, the artists have taken a deep dive into experimentation with electronic sound and storytelling.
				</p>
				<p style={{ color: '#5f8bb4' }}>For booking: info[at]elmorabba3[dot]com</p>
			</div>
		</Page>
	);
};
export default AboutPage;
