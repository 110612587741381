import React from 'react';
import Page from '../../components/Page';
import styles from './listen.module.scss';

function ListenPage() {
	return (
		<Page>
			<h1 className={styles.head}>Listen</h1>
			<div className={styles.container}>
				<iframe
					title="elmorabba3 taraf alkhait"
					className={styles.secondAlbum}
					src="https://open.spotify.com/embed/album/5HGR9PdFE6Wz0wqsLcCdrm"
					width="100%"
					height="400"
					frameBorder="0"
					allowtransparency="true"
					allow="encrypted-media"
				/>
				<iframe
					title="elmorabba3 elmorabba3"
					className={styles.firstAlbum}
					src="https://open.spotify.com/embed/album/4noxSq67DwvvPBwYaQiYBH"
					width="100%"
					height="400"
					frameBorder="0"
					allowtransparency="true"
					allow="encrypted-media"
				/>
			</div>
		</Page>
	);
}

export default ListenPage;
