import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import ReactGA from 'react-ga';
import styles from './App.module.scss';
// import logo from './assets/logo.png';
// import ConcertsPage from './pages/Concerts';
import AboutPage from './pages/About';
import ListenPage from './pages/Listen';
import { gaEvent, gaPageView } from './utils';
import NewsletterPage from "./pages/Newsletter";

const fullpagelicenseKey = process.env.REACT_APP_FULL_PAGE;
ReactGA.initialize('UA-141904600-1');
ReactGA.pageview('/home');

// const pluginWrapper = () => {
// 	require('fullpage.js/vendors/scrolloverflow');
// };
const MenuItem = ({ title, link }) => (
	<a
		onClick={() => gaEvent('menu', 'click', title)}
		href={`#${link ? link : title}`}
		className={styles.menuItem}
	>
		{title}
	</a>
);
const Menu = () => (
	<div className={styles.menu}>
		<MenuItem title="home" />
		<MenuItem title="about us" link="about-us" />
		<MenuItem title="listen" />
		<MenuItem title="mailing list" link="mailing-list"/>
		{/* <MenuItem title="concerts" /> */}
	</div>
);

const Icon = ({ icon, link }) => {
	return (
		<a href={link} target={"_blank"} rel={"noreferrer"} onClick={() => {
			gaEvent('scoial media icons', 'click', icon);
		}}>
		<img
			src={require(`./assets/icons/${icon}.svg`)}
			alt={icon}
		/>
		</a>
	);
};

const Footer = () => (
	<div className={styles.footer}>
		<Icon icon="facebook" link="https://www.facebook.com/El.Morabba3" />
		<Icon icon="twitter" link="https://twitter.com/ElMorabba3" />
		<Icon icon="instagram" link="https://www.instagram.com/elmorabba3/" />
		<Icon icon="telegram" link="https://t.me/+nD1Z_Sj8kP8yZmY03" />
		<Icon
			icon="spotify"
			link="https://open.spotify.com/artist/4yTcSEYSpMUQ5t5XFd0uPU"
		/>
		<Icon
			icon="youtube"
			link="https://www.youtube.com/channel/UCe9TAXjLazRiuRSJrFO_FJw"
		/>
		<Icon icon="bandcamp" link="https://elmorabba3.bandcamp.com/" />
		<Icon icon="soundcloud" link="https://soundcloud.com/elmorabba3" />
	</div>
);

const Section = ({ children, className }) => (
	<div className={`section ${className}`}>{children}</div>
);

const Home = () => (
	<div className={styles.homeInner}>
		{/* <img className={styles.logo} src={logo} alt="elmorabba3 logo" /> */}
	</div>
);

function App() {
	return (
		<div className={styles.app}>
			<Menu />
			<ReactFullpage
				// pluginWrapper={pluginWrapper}
				licenseKey={fullpagelicenseKey}
				scrollOverflow={true}
				afterLoad={(origin, destination, direction) =>
					gaPageView(destination ? destination.anchor : null)
				}
				anchors={['home', 'about-us', 'listen', 'mailing-list']}
				render={({ state, fullpageApi }) => {
					return (
						<ReactFullpage.Wrapper>
							<Section className={styles.home}>
								<Home />
							</Section>
							<Section>
								<AboutPage />
							</Section>
							<Section>
								<ListenPage />
							</Section>
							<Section>
								<NewsletterPage />
							</Section>
							{/* <Section>
								<ConcertsPage />
							</Section> */}
						</ReactFullpage.Wrapper>
					);
				}}
			/>
			<Footer />
		</div>
	);
}

export default App;
